<template>
  <div class="top-section">
    <div class="flex-column">
      <textarea class='message-input question' v-model="newQuestion" placeholder="Enter the question"></textarea>
      <textarea class='message-input answer' v-model="newAnswer" placeholder="Enter the response"></textarea>
    </div>
    <MyButton @click="uploadPair" class="button-save" center>Save</MyButton>
    
    <h2 class="title">Uploaded Knowledge Base</h2>
    <div v-if="knowledgebasePairs.length == 0">
      Nothing here yet
    </div>
    <div class="pair-section">
      
      <div class='pair' :class="{editing: pair.editing}" v-for="pair in knowledgebasePairs" :key="pair.id">
        <div v-if="pair.editing" class="editing-text">
          <textarea class='message-input question' v-model="editQuestion"></textarea>
          <textarea class='message-input answer' v-model="editAnswer" ></textarea>
          <div class="flex-elem">
          <MyButton @click="uploadPair({id: pair.id})" class="button-save" center>Save</MyButton>
          <MyButton @click="cancelUpdate({id: pair.id})" class="button-save" center>Cancel</MyButton>
          </div>
        </div>
        <div v-else>
          <div class="message-input question">{{pair.question}}</div>
          <div class="message-input answer">{{pair.answer}}</div>
          <div class="action-button">
            <MyButton @click="deletePair(pair.id)" class="button-save" center>Delete</MyButton>
            <MyButton @click="editPair(pair.id, pair.answer, pair.question)" class="button-save" center>Edit</MyButton>
          </div>
        </div>
       
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { useSiteStore } from '@/stores/site.js';
import { doRequest } from '@/utils';
import MyButton from '@/components/MyButton.vue';

export default {
  components: {
    MyButton,
  },
  data() {
    return {
      newQuestion: '',
      newAnswer: '',
      editQuestion: '',
      editAnswer: '',
      currentEditingPair: null,
    };
  },
  async mounted() {
    await this.$router.isReady();
    await this.loadKnowledgeBasePairs();
  },
  computed: {
    ...mapState(useSiteStore, ['knowledgebasePairs']),
  },
  methods: {
    ...mapActions(useSiteStore, [
        'loadKnowledgeBasePairs', 
        'addPair', 
        'dropAlert', 
        'removeKnowledgePair',
        'updatePair',
      ]),
    
    async uploadPair({id}) {
      console.log('AAAAAAAAAAAAAAAAAA')
      console.log('uploadPair', id, this.newQuestion, this.newAnswer, this.editQuestion, this.editAnswer)

      let question = this.newQuestion.trim();
      let answer = this.newAnswer.trim();
      if (id) {
        question = this.editQuestion.trim();
        answer = this.editAnswer.trim();
      }
      console.log('uploadPair', question, answer)
      if (!question) {
        this.dropAlert({ type: 'error', message: 'Question is required' });
        return
      }
      if (!answer) {
        this.dropAlert({ type: 'error', message: 'Answer is required' });
        return
      }
      if (id) {
        this.updatePair({id, data: {editing: false, question, answer}});
      } else {
          this.addPair({
          question: question,
          answer: answer,
        })
      }
   
      const resp = await doRequest({
        sentData: {
          question: question,
          answer: answer,
          slug: this.$route.params.siteSlug,
          id,
        },
        endpoint: id ? 'edit-pair' : 'upload-pair',
      });
      console.log(resp)
      if (id) {
        this.editQuestion = '';
        this.editAnswer = '';
      } else {
        this.newQuestion = '';
        this.newAnswer = '';
      }
    },
    cancelUpdate({id}) {
      if (this.currentEditingPair) {
        this.updatePair({id, data: {editing: false}});
        this.currentEditingPair = null;
      }
    },
    editPair(id, answer, question) {
      this.cancelUpdate({id: this.currentEditingPair});
      this.currentEditingPair = id;
      this.editQuestion = question;
      this.editAnswer = answer;
      this.updatePair({id, data: {editing: true}});

      console.log('editPair', id)
    },
    async deletePair(id) {
      console.log('deletePair', id)
      this.removeKnowledgePair({id});
      this.dropAlert({ type: 'success', message: 'Knowledge Base Pair has been deleted' });


      const resp = await doRequest({
        sentData: {
          id
        },
        endpoint: 'delete-knowledge-base-pair',
      });
      console.log(resp)
    },
  }
  
};
</script>



<style scoped lang="scss">

  .pair-section {
    width: 100%;
    height: calc(100vh - 330px);
    overflow-y: auto;
  }
  .pair {
    display: flex;
    gap: 5px;
    margin-bottom: 20px;
    flex-direction: column;
    position: relative;
    &:hover:not(.editing) {
      .message-input {
        filter: blur(1.5px);
      }
      .action-button {
        display: flex;
        transition: all 0.3s;
        opacity: 1;
      }
    }
  }
  .title {
    margin-top: 40px;
  }
  .message-input {
    background-color: white;
    border-radius: 5px;
    box-sizing: border-box;
    flex: 1;
    min-width: 100%;
    max-width: 100%;
    resize: none;
    width: 100%;
    border: 1px solid #bc0ad9;
    padding: 10px;
    &:focus {
      border-color: #bc0ad9;
      outline: 1px solid #bc0ad9;
    }
    &.question {
      height: 50px;
      min-height: 50px;
      max-height: 50px;
    }
    &.answer {
      margin-top: 5px;
      height: 100px;
      min-height: 100px;
      max-height: 100px;
    }
  }

  .button-save {
    margin-top: 10px;
    width: 200px;
  }
  .action-button {
    opacity: 0.8;
    border-radius: 5px;
    display: none;
    position: absolute;
    top: 0;
    right: 2%;
    bottom: 0;
    align-items: center;
    justify-content: center;
    margin: auto;
    transition: all 0.3s;
    flex-direction: column;
    gap: 10px;
    .button-save {
      width: 100px;
    }
  }
  .flex-elem {
    display: flex;
    gap: 20px;
    
  }

  .knowledge-base-inputs {
    display: flex;
    gap : 10px;
  }
  .flex-column {
    display: flex;
    flex-direction: column;
  }
</style>