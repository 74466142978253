<template>
  <div class="InputWrap">
    <div class="InputLbl" :isFocused="modelValue || inputFocus ? 'fill' : ''">
      <slot> </slot>
    </div>
    <input
      :class="{actionIcon}"
      :isFocused="modelValue || inputFocus ? 'fill' : ''"
      :value="modelValue"
      :type="`${type || 'text'}`"
      :disabled="disabled"
      :placeholder="placeholder || ''"
      @input="inputHandler"
      @focus='inputFocus = true'
      @blur='inputFocus = false'
      @keydown.enter="$emit('on-enter')"
    />
    <div v-if="actionIcon" class="action-icon" @click="$emit('icon-click')">
      <i :class="`my-icon my-icon-${actionIcon}`"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MyInput',

  props: {
    modelValue: String,
    actionIcon: String,
    type: String,
    disabled: Boolean,
    placeholder: String,
  },
  data() {
    return {
      inputFocus: true,
    };
  },
  methods: {
    inputHandler(event) {
      this.$emit('update:modelValue', event.target.value);
    },
  },
};
</script>

<style scoped lang="scss">

input.actionIcon {
    padding-right: 30px;
}

.action-icon {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}
.InputWrap {
  width: 100%;
  height: 38px;
  position: relative;
  /* margin-left: calc(50% - 100px); */
}

.InputLbl {
  border-radius:5px;
  position: absolute;
  top: 10px;
  left: 10px;
  pointer-events: none;
  transition: 0.3s;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #637488;
}

.InputLbl[isfocused = "fill"] {
  background-color: #E5E5E5;
  top: -8px;
  left: 5px;
  font-size: 14px;
  padding: 2px 5px;
  font-size: 14px;
  line-height: 14px;
  color: #637488;

}

input {
  outline: 0;
  background-color: #E5E5E5;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  border: 1px solid #637488;
  border-radius: 5px;
  transition: 0.3s;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #0B315E;
}

input[isfocused = "fill"] {
  border-color: $activeFixed;
}
</style>
