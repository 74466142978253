<template>
  <div class="addWebsiteDialog" center vertical-flex>
    <Logo center>alreadybot</Logo>
    <div class="SignDialogTitle" id="AddWebsiteTitle">
      On which website you are going to place the widget?
    </div>
    <div class="fl-col fl-gap-1 w-100">
      <MyInput v-model="inputValueDomain" center>Domain</MyInput>
      <MyButton @click="onBtnCompleteClick" center>Complete</MyButton>
    </div>
    <div class="SignDialogErrLbl" v-if="errMsg" center>{{ errMsg }}</div>
  </div>
</template>

<script>
import Logo from '@/components/Logo.vue';
import MyInput from '@/components/MyInput.vue';
import MyButton from '@/components/MyButton.vue';
import { doRequest } from '@/utils';

export default {
  name: 'AddWebsite',
  components: {
    Logo,
    MyInput,
    MyButton,
  },
  data() {
    return {
      errMsg: '',
      inputValueDomain: '',
    };
  },
  methods: {
    async onBtnCompleteClick() {
      if (this.inputValueDomain) {
        this.errMsg = '';
        const responseBody = await doRequest({
            endpoint: 'add-website',
            sentData: {
              domain: this.inputValueDomain,
            },
          }
        );
        if (!responseBody.error) {
          this.errMsg = '';
          this.$router.push({ name: 'WidgetSettings', params: { siteSlug: responseBody.slug } });
          // this.$router.push({ name: 'Dashboard'})
        } else {
          this.errMsg = `Error: ${responseBody.error}`;
          console.log('I am here');
        }
      } else this.errMsg = 'Error: some field(s) are empty!!!';
      // window.connection.send(JSON.stringify({action: 'BTN_LOGIN_CLICKED', email: this.inputValueEmail, password: this.inputValuePassword,}))
    },
  },
};
</script>

<style scoped>
#AddWebsiteTitle {
  margin-top: 15px;
  max-width: 200px;
  text-align: center;
}

/* position:absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
</style>
