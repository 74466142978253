<template>
  <div class="wrapper-full-height fl-col">
    <div class="chat-header">
      <div class="educate-header__item">
        <div class="educate-header__item-image">
          <img
            v-if="bot_info.bot_icon"
            class="my-bot-logo"
            :src="bot_info.bot_icon"
          />
          <i v-else class="my-icon my-icon-logo"></i>
        </div>
        <div class="educate-header__item-info">
          <div class="name-bot">{{ bot_info.bot_name }}</div>
          <div class="text-under-name">Assistant</div>
        </div>
      </div>
      <div class="educate-header__item">
        <div class="educate-header__item-info">
          <div class="name-bot">User</div>
        </div>
        <div class="educate-header__item-image">
          <i class="my-icon my-icon-user"></i>
        </div>
      </div>
    </div>
    <div class="wrapper-conversation fl-col fl-gap-1">
      <div class="content-conversation custom-scroll" v-if="conversations.length">
        <Message
          v-for="item in conversations"
          :key="`msg_${item.id}`"
          :id="item.id"
          :message="item.message"
          :isBot="item.isBot"
          @correction="(e) => correctionMessage(e)"
        />
      </div>

      <div class="content-conversation fl-col fl-j-cent" v-else>
        <div class="text-center">
          To educate bot just start talking to him and edit his answers if they are
          wrong or you don't like them
        </div>
      </div>

      <div>
        <MyInput
          v-model.trim="inputMessage"
          class="message-input"
          :action-icon="'plane'"
          :disabled="disabledInput"
          :placeholder="'Type a message...'"
          @icon-click="sendMessage"
          @on-enter="sendMessage"
        />
      </div>
    </div>
      
  </div>
</template>

<script setup>
import { doRequest } from '@/utils';
import { v4 as uuidv4 } from 'uuid';
import { nextTick, onMounted, ref } from 'vue';

import Message from '@/components/Message';
import MyInput from '@/components/MyInput';

import { useSiteStore } from '@/stores/site';
const siteStore = useSiteStore();

const bot_info = siteStore.domain_bot;

const conversations = ref([]);
const inputMessage = ref('');
const disabledInput = ref(false);

const sendMessage = async () => {
  console.log('sendMessage', inputMessage.value);

  const message = inputMessage.value;
  if (!message) return;

  disabledInput.value = true;
  inputMessage.value = '';

  const data = { id: uuidv4(), message, slug: siteStore.slug};
  conversations.value.push({
    ...data,
    isBot: false,
  });
  console.log('sendMessage', data, conversations.value);

  let answer;
  try {
    answer = await doRequest({
      sentData: data,
      endpoint: 'educate-message',
    });
  } catch (error) {
    console.error(error);
    disabledInput.value = false;
    return;
  }
  
  conversations.value.push({
    id: answer.id,
    message: answer.message,
    answer_to_id: answer.answer_to_id,
    isBot: true,
  });
  disabledInput.value = false;
}

const correctionMessage = async (editedMessage) => {
  const message = conversations.value.find((x) => x.id === editedMessage.id);
  if (!message) {
    console.error('Message not found');
    return;
  }

  message.message = editedMessage.message;
  await doRequest({
    sentData: {
      id: message.id,
      message: editedMessage.message,
      answer_to_id: message.answer_to_id,
      slug: siteStore.slug,
    },
    endpoint: 'correct-message',
  });
}

onMounted(() => nextTick(() => {
  const input = document.querySelector('.message-input input');
  if (input) input.focus();
}));
</script>

<style lang="scss" scoped>
.educate-header__item {
  display: flex;
  align-items: center;

  &-image {
    .my-icon {
      font-size: 30px;
    }

    .my-bot-logo{
      width: 35px;
      height: 35px;
      border-radius: 50%;
    }
  }

  &-info {
    margin: 0 10px;
  }

  .name-bot {
    font-size: 14px;
    font-weight: bold;
    color: $text;
  }
  
  .text-under-name {
    font-size: 14px;
  }
}

.wrapper-sender {
  width: 100%;
  box-sizing: border-box;
  margin-top: 10px;
}

.send-message {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);

  i {
    color: #bc0ad9;
  }
}

.message-input {
  padding: 0;
  border: none;

  &:deep(input) {
    background-color: white;
    height: 35px;
    width: 100%;
    border-color: #bc0ad9;
  }

  &:deep(.action-icon) {
    top: 50%;
    transform: translateY(-50%);
    i {
      color: #bc0ad9;
    }
  }
}
</style>
