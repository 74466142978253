<template>
  <div class="SignUpDialog" center vertical-flex>
    <Logo center>alreadybot</Logo>
    <div class="SignDialogTitle" center>Sign up</div>
    <div class="fl-col fl-gap-1 w-100">
      <MyInput v-model="inputValueEmail" type="email" center>E-mail</MyInput>
      <MyInput v-model="inputValuePassword" type="password">Your password</MyInput>
      <MyInput v-model="inputValueRepeatedPassword" center type="password">Repeat password</MyInput>
      <MyButton @click="onBtnSignUpClick" center :disabled="requestProgress">Sign me up</MyButton>
    </div>
    <div class="SignDialogErrLbl" v-if="errMsg" center>{{ errMsg }}</div>
    <div class="SignDialogLbl" center>
      Do have an account? &nbsp; <router-link :to="{ name: 'SignIn' }"> Sign in</router-link>
    </div>
  </div>
</template>

<script setup>
import Logo from '@/components/Logo.vue';
import MyButton from '@/components/MyButton.vue';
import MyInput from '@/components/MyInput.vue';
import { useSiteStore } from '@/stores/site.js';
import { doRequest } from '@/utils';
import { ref } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const siteStore = useSiteStore();

const errMsg = ref('');
const inputValueEmail = ref('');
const inputValuePassword = ref('');
const inputValueRepeatedPassword = ref('');
const requestProgress = ref(false);

async function onBtnSignUpClick() {
  if (inputValueEmail.value && inputValuePassword.value && inputValueRepeatedPassword.value) {
    errMsg.value = '';
    if (inputValuePassword.value === inputValueRepeatedPassword.value) {
      errMsg.value = '';

      requestProgress.value = true;
      let responseBody;
      try {
        responseBody = await doRequest({
          endpoint: 'signup',
          sentData: { email: inputValueEmail.value, password: inputValuePassword.value },
        });
      } catch (error) {
        console.log('dropAlert111: ', error);
        siteStore.dropAlert(
          { type: 'error', message: `Something went wrong, try to refresh your page ${error}` },
        );
        return;
      } finally {
        requestProgress.value = false;
      }

      if (!responseBody.error) {
        errMsg.value = '';
        router.push({ name: 'AddWebsite' });
        // this.$router.push({ name: 'Dashboard'})
      } else {
        errMsg.value = `Error: ${responseBody.error}`;
      }
    } else {
      errMsg.value = "Error: passwords aren't equal!";
    }
  } else {
    errMsg.value = 'Error: some field(s) are empty!';
  }
}
</script>

<style scoped>
.ButtonWrap {
  width: unset;
}
</style>
