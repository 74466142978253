<template>
  <div class="widget-snippet">
        <h2>Widget Snippet</h2>
        <p>Copy and paste the following code snippet into your website <strong>anywhere</strong> to add the chat widget.</p>

        <div class="code-snippet">
          <div class="code">
            <!-- {{ snippet }} -->
            <span v-for="(line, index) in snippetLines" :key="index">
              <span class="line-number">{{ index + 1 }}</span>
              <span class="line-content">{{ line }}</span>
            </span>
          </div>
        </div>
        
        <div class="buttons">
          <MyButton class="copy-code-btn" @click="copyToClipboard">Copy to clipboard</MyButton>
        </div>
    </div>
</template>

<script setup>
import MyButton from '@/components/MyButton.vue';
import { useSiteStore } from '@/stores/site.js';
import { onMounted, ref } from 'vue';

const siteStore = useSiteStore();

const snippet = ref('');
const snippetLines = ref([]);

const copyToClipboard = async () => {
  navigator.clipboard.writeText(snippet.value).then(async () => {
    await siteStore.dropAlert({ type: 'success', message: 'Copied to clipboard' });
  });
};

const short_id = siteStore.short_id;
console.log('short_id', short_id);

onMounted(() => {
  snippet.value = '<script>\n' +
                   '(function() {\n'+
                   '    // Alreadybot.com chatwidget code\n' +
                   '    // window.alreadybot = {setUserToken: function(x) {window.alreadybot.token = x;}};\n' +
                   '    var s = document.createElement("script");\n'+
                   '    var ts = new Date().getTime();\n' +
                   `    var url = "https://alreadybot.com/widget.js?ts=ts${short_id ? '&id=' + short_id : ''}";\n` +
                   '    s.setAttribute("src", url);\n' +
                   '    document.body.appendChild(s);\n' +
                   '})();\n' +
                   '<'+'/script>';
  snippetLines.value = snippet.value.split('\n');
});
</script>

<style scoped>
.widget-snippet {
  display: flex;
  flex-direction: column;
  gap: 5px;

  width: 100%;
}

.code-snippet {
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 4px;
}

.code {
  font-family: monospace;
  white-space: pre-wrap;
  margin: 0;
}

.code span {
  display: flex;
}

.line-number {
  width: 30px;
  text-align: right;
  margin-right: 10px;
  color: #888;
}

.line-content {
  flex: 1; /* Ensures line content takes up the rest of the line */
}


.copy-code-btn {
  display: flex;
  justify-content: center;

  width: fit-content;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
</style>