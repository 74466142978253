<template>
  <div class="ButtonWrap">
    <button @click="btnClick" class="clickable" :disabled="disabled">
      <div class="BtnLbl">
        <slot> </slot>
      </div>
    </button>
  </div>
</template>

<script>
export default {
  name: 'MyButton',
  props: {
    width: Number,
    height: Number,
    background: String,
    borderColor: String,
    disabled: Boolean,
  },
  data() {
    return {};
  },
  methods: {
    btnClick() {
      this.$emit('onBtnClick');
    },
  },
};
</script>

<style lang="scss" scoped>

Button[disabled] {
  opacity: 0.5;
  pointer-events: none;
}

.ButtonWrap {
  position: relative;
  width: 150px;
  margin-top: 20px;
}
button {
  cursor: pointer;
  width: 100%;
  height: 38px;
  text-align: center;
  align-items: center;
  border-radius: 5px;
  color: #fff;
  background: $active;
  border-style: solid;
  border: 0;
  box-shadow: $buttonShadow;
}
button:hover {
  background-color: rgb(255, 173, 41);
}
button:active {
  background-color: rgb(153, 106, 29);
}

.BtnLbl {
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  color: #fff;
}
</style>
