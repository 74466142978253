<template>
  <div class="base-select">
    <v-select
      v-model="selected"
      :options="options"
      :label="label"
      :placeholder="placeholder"
      :class="{'without-clear-action': hideClearAction}"
    >
    <template #list-footer>
      <slot name="list-footer"></slot>
    </template>
  </v-select>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, ref, watch } from 'vue';

// Props
const props = defineProps({
  options: {
    type: Array,
    default: () => [],
  },
  modelValue: {
    type: Object,
    default: () => null,
  },
  label: {
    type: String,
    default: () => '',
  },
  placeholder: {
    type: String,
    default: () => '',
  },
  hideClearAction: {
    type: Boolean,
    default: () => false,
  }
});
const selected = ref(props.modelValue);

// Emits
const emit = defineEmits(['input', 'update:modelValue']);
watch(() => selected.value, (value) => {
    emit('input', value);
  }
);
watch(() => props.modelValue, (value) => {
  selected.value = value;
});
</script>

<style scoped>
@import 'vue-select/dist/vue-select.css';

.base-select :deep(.vs__dropdown-toggle[aria-expanded='false']) * {
  cursor: pointer;
}

.without-clear-action :deep(.vs__clear){
  display: none;
}
</style>