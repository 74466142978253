<template>
  <div class="avatar-block">
    <div class="Input">
      <img class="clickable" :src="modelValue" @click="this.$refs.input.click()" />
      <div class="InputLbl">Avatar</div>
    </div>
    <input
      type="file"
      ref='input'
      @change="readFile"
    />
    <div class="right">
      <div class="clickable link" @click="generateRandom()">Generate random</div>
      <div class="clickable link" @click="this.$refs.input.click()">Click to select</div>
    </div>
  </div>
</template>

<script>
import {doRequest} from '@/utils.js';

function resizeB64URL(datas, w, h) {
    return new Promise((resolve) => {
        const img = document.createElement('img');
        img.onload = function() {        
            var canvas = document.createElement('canvas');
            var ctx = canvas.getContext('2d');
            canvas.width = w;
            canvas.height = h;
            ctx.drawImage(this, 0, 0, w, h);
            resolve(canvas.toDataURL());
        };
        img.src = datas;
    })
}
export default {
  name: 'MyInput',

  props: {
    modelValue: String,
    actionIcon: String,
  },
  data() {
    return {
      inputFocus: false,
    };
  },
  methods: {
    readFile() {
      const reader = new FileReader();
      reader.readAsDataURL(this.$refs.input.files[0]);
      const vm_ = this;
      reader.onload = async function () {
        const resized = await resizeB64URL(reader.result, 128, 128);
        vm_.$emit('update:modelValue', resized);
      };
      reader.onerror = function (error) {
        console.error('Error reading file: ', error);
      };
    },
    async generateRandom() {
      const data = await doRequest({ endpoint: 'get_random_person' });
      const resized = await resizeB64URL(`data:image/png;base64,${data.image}`, 128, 128);
      this.$emit('update:modelValue', resized);
    },
  },
};
</script>

<style scoped lang="scss">

.avatar-block {
  display: flex;
  .right {
    padding-left: 34px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
  }
}
input {
  display: none;
}

.Input {
  border-radius: 5px;
  border: 1px solid $activeFixed;
  padding: 15px 10px 10px 10px;
  position: relative;
  >img {
    display: block;
    position: relative;
    z-index: 1;
    width: 128px;
    height: 128px;
    border-radius: 50%;
  }
}

.InputLbl {
  background-color: #E5E5E5;
  top: -8px;
  left: 5px;
  font-size: 14px;
  padding: 2px 5px;
  line-height: 14px;
  color: #637488;
  position: absolute;
}




</style>
