import { doRequest } from '@/utils.js';
import { defineStore } from 'pinia';

export const useSiteStore = defineStore('site', {
  state: () => {
    return {
      alerts: [],
      availableDomains: [],

      domain: null,
      short_id: null,
      slug: null,
      usageData: null,
      tokenBalance: null,
      knowledgebasePairs: null,
      domain_bot: {
        bot_name: 'Emanuel',
        bot_icon: null,
      },
    };
  },
  getters: {
    currentDomain(state) {
      return state.availableDomains.find(
        domain_ => domain_.slug === this.slug
      );
    }
  },
  actions: {
    async loadAvailableDomains({ siteSlug = null }) {
      const availableDomains = await doRequest({
        endpoint: 'get-all-sites',
        method: 'GET',
      });
      console.log('🟠 availableDomains =', availableDomains);

      if(!availableDomains)
        return console.error('No availableDomains');

      availableDomains.sort((a, b) => a.slug.localeCompare(b.slug));
      this.availableDomains = availableDomains;

      if(siteSlug)
        this.setDomainBySlug({ siteSlug: siteSlug })
    },
   
    async loadUsageData() {
      console.log('loadUsageData');
      const infoDomain = await doRequest({
        endpoint: 'get-usage-data',
        method: 'GET',
      });
      const mapped = Object.values(infoDomain.usage_data.reduce((acc, item) => {
        let store = acc[item.date] || {'total': 0, 'date': new Date(item.date)};

        store[item.website] = item.count;
        store.total += item.count;

        acc[item.date] = store;
        return acc;
      }, {}));
      this.usageData = mapped.sort((a, b) => a - b );
      console.log('this.usageData', mapped);

      this.tokenBalance = infoDomain.token_balance;
    },
    setDomainBySlug({ siteSlug }){
      const domainBySlug = this.availableDomains.find(
        domain_ => domain_.slug === siteSlug
      );
      if(!domainBySlug){
        console.error('Missed info for domain with slug', siteSlug);
        this.dropAlert({message: 'Domain is not found', type: 'error'})
        return;
      }
      this.domain = domainBySlug.domain;
      this.short_id = domainBySlug.short_id;
      this.slug = domainBySlug.slug;
      this.domain_bot = {
        bot_name: domainBySlug.bot_name,
        bot_icon: domainBySlug.bot_icon,
      };
      localStorage.setItem('currentDomainSlug', this.slug)
      return domainBySlug;
    },
    async addPair({ question, answer }) {
      console.log('addPair', question, answer);
      this.knowledgebasePairs.unshift({
        question,
        answer,
        createdAt: new Date(),
      })
    },
    
    async loadKnowledgeBasePairs() {
      const infoDomain = await doRequest({
        endpoint: 'get-knowledgebase-pairs',
        sentData: { slug: this.slug },
      });
      let messages = infoDomain.messages;
      let pairs = {}
      for(let message of messages){
        if (!message.answer_to_id){
          pairs[message.id] = {
            question: message.message,
            createdAt: message.createdAt,
            id: message.id,
          }
        } else {
          pairs[message.answer_to_id].answer = message.message;
        }
      }
      this.knowledgebasePairs = Object.values(pairs);
    },
    async removeKnowledgePair({id}) {
      console.log('removeKnowledgePair', id);
      this.knowledgebasePairs = this.knowledgebasePairs.filter(pair => pair.id !== id);
    },
    updatePair({id, data}) {
      this.knowledgebasePairs = this.knowledgebasePairs.map(pair => {
        if(pair.id === id){
          return {
            ...pair,
            ...data,
          }
        }
        return pair;
      });
    },
    async dropAlert({ message, type }) {
      console.log('Show alert', message, type);

      const alertID = Math.floor(Math.random() * 10_000);
      this.alerts.push({
        id: alertID,
        message,
        type,
      });

      setTimeout(() => {
        this.closeAlert({ id: alertID });
      }, 5_000);
    },
    async closeAlert({ id }) {
      this.alerts = this.alerts.filter((alert) => alert.id !== id);
    },

        // async loadDomain({ siteSlug }) {
    //   // console.log('siteSlug', siteSlug);
    //   const infoDomain = await doRequest({
    //     endpoint: 'getSiteBySiteslug',
    //     sentData: { siteSlug },
    //   });
    //   this.domain = infoDomain.domain;
    //   this.short_id = infoDomain.short_id;
    //   this.domain_bot = {
    //     bot_name: infoDomain.bot_name,
    //     bot_icon: infoDomain.bot_icon,
    //   }
    // },
  },
})