<template>
  <div class="SignInDialog" center vertical-flex>
    <Logo center>alreadybot</Logo>
    <div class="SignDialogTitle" center>Sign in</div>
    <div class="fl-col fl-gap-1 w-100">
      <MyInput v-model="inputValueEmail" type="email" center>E-mail</MyInput>
      <MyInput v-model="inputValuePassword" type="password" center>Your password</MyInput>
      <MyButton @click="onBtnLoginClick" center :disabled="requestProgress">Log me in</MyButton>
      <div class="SignDialogErrLbl" v-if="errMsg" center>{{ errMsg }}</div>
    </div>
    <div class="SignDialogLbl" center>
      Don't have an account?&nbsp;<router-link :to="{ name: 'SignUp' }"> Sign up</router-link>
    </div>
  </div>
</template>

<script setup>
import Logo from '@/components/Logo.vue';
import MyButton from '@/components/MyButton.vue';
import MyInput from '@/components/MyInput.vue';
import { useSiteStore } from '@/stores/site.js';
import { doRequest } from '@/utils';
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();

const siteStore = useSiteStore();

const errMsg = ref('');
const inputValueEmail = ref('');
const inputValuePassword = ref('');
const requestProgress = ref(false);

onMounted(async () => {
  await router.isReady();
  if (this.$route.query.reason === 'expired') {
    errMsg.value = 'Authorization expired. Please relogin';
  } else {
    errMsg.value = '';
  }
});

async function onBtnLoginClick() {
  if (inputValueEmail.value && inputValuePassword.value) {
    errMsg.value = '';
    requestProgress.value = true;
    let responseBody;
    try {
      responseBody = await doRequest({
        endpoint: 'signin',
        sentData: {
          email: inputValueEmail.value,
          password: inputValuePassword.value,
        },
      });
    } catch (error) {
      siteStore.dropAlert({ type: 'error', message: 'Error: ' + error.message });
      return;
    } finally {
      requestProgress.value = false;
    }
    if (!responseBody.error) {
      errMsg.value = '';
      const siteSlug = responseBody.lastDomainSlug;
      if (siteSlug) {
        router.push({ name: 'WidgetSettings', params: { siteSlug } });
      } else {
        router.push({ name: 'AddWebsite' });
      }
    } else {
      errMsg.value = `Error: ${responseBody.error}`;
    }
  } else errMsg.value = 'Error: some field(s) are empty!!!';
  // window.connection.send(JSON.stringify({action: 'BTN_LOGIN_CLICKED', email: this.inputValueEmail, password: this.inputValuePassword,}))
}
</script>

<style scoped>
.ButtonWrap {
  width: unset;
}

/* position:absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
</style>
