import { createRouter, createWebHistory } from 'vue-router';
import StartPage from '@/views/StartPage.vue';
import SignIn from '@/views/SignIn.vue';
import SignUp from '@/views/SignUp.vue';
import Dashboard from '@/views/Dashboard.vue';
import AddWebsite from '@/views/AddWebsite.vue';
import MainView from '@/views/MainView.vue';
import WidgetSettings from '@/views/WidgetSettings.vue';
import WidgetTalks from '@/views/WidgetTalks.vue';
import WidgetEducate from '@/views/WidgetEducate.vue';
import WidgetOverview from '@/views/WidgetOverview.vue';
import Conversation from '@/views/Conversation.vue';
import WidgetKnowledge from '@/views/WidgetKnowledge.vue';
import WidgetSnippet from '@/views/WidgetSnippet.vue';
import Pricing from '@/views/Pricing.vue';
const routes = [
  {
    path: '/',
    name: 'StartPage',
    component: StartPage,
  },
  {
    path: '/signIn',
    name: 'SignIn',
    component: SignIn,
  },
  {
    path: '/signUp',
    name: 'SignUp',
    component: SignUp,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
  },
  {
    path: '/add-website',
    name: 'AddWebsite',
    component: AddWebsite,
  },
  {
    path: '/widget/:siteSlug/',
    name: 'MainView',
    component: MainView,
    children: [
      {
        path: 'settings',
        name: 'WidgetSettings',
        component: WidgetSettings,
      },
      {
        path: 'talks',
        name: 'WidgetTalks',
        component: WidgetTalks,
      },
      {
        path: 'talks/session/:sessionId',
        name: 'Conversation',
        component: Conversation,
      },
      {
        path: 'educate',
        name: 'WidgetKnowledge',
        component: WidgetKnowledge,
      },
      {
        path: 'educate',
        name: 'WidgetEducate',
        component: WidgetEducate,
      },
      {
        path: 'overview',
        name: 'WidgetOverview',
        component: WidgetOverview,
      },
      {
        path: 'snippet',
        name: 'WidgetSnippet',
        component: WidgetSnippet,
      },
      {
        path: 'Pricing',
        name: 'Pricing',
        component: Pricing,
      },
      
    ],
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    redirect: '/' 
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
