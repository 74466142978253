import dayjs from 'dayjs';
import { createPinia } from 'pinia';
import { createApp } from 'vue';
import vSelect from 'vue-select';
import App from './App.vue';
import router from './router';

import 'vue-select/dist/vue-select.css';
import './assets/iconfont/abfont.css';

window.dayjs = dayjs;

const pinia = createPinia()

const app = createApp(App);

app.use(pinia);
app.component('v-select', vSelect);
app.use(router).mount('#app');
