<template>
  <div class="widget-snippet">
        <h2>Pricing Information</h2>
        <p>Select Appropriate plane for your use.</p>
        <section class="pricing-section">
            <div class="pricing-item">
                <h4>Free Plan</h4>
                <p>2 Domains</p>
                <p>Price: Free</p>
                <p>Includes 200,000 tokens</p>
            </div>
            <div class="pricing-item">
                <h4>Standard Plan</h4>
                <p>10 Domains</p>
                <p>Price: $4.99/month</p>
                <p> + Quota $9.99 for 2,000,000 tokens</p>
            </div>
            
        </section>
       
    </div>
</template>

<script setup>
import { useSiteStore } from '@/stores/site.js';
import { onMounted, } from 'vue';

const siteStore = useSiteStore();


const short_id = siteStore.short_id;
console.log('short_id', short_id);

onMounted(() => {
});
</script>

<style scoped lang="scss">
.widget-snippet {
  display: flex;
  flex-direction: column;
  gap: 5px;

  width: 100%;
}

.code-snippet {
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 4px;
}

.code {
  font-family: monospace;
  white-space: pre-wrap;
  margin: 0;
}

.code span {
  display: flex;
}

.line-number {
  width: 30px;
  text-align: right;
  margin-right: 10px;
  color: #888;
}

.line-content {
  flex: 1; /* Ensures line content takes up the rest of the line */
}


.copy-code-btn {
  display: flex;
  justify-content: center;

  width: fit-content;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
.pricing-section {
    display: flex;
    gap: 10px;
    .pricing-item {
        border-radius: 5px;
        border: 2px solid #ccc;
        padding: 10px;
        flex: 1;
        cursor: pointer;
        &:hover {
            background-color: #dddbdb;
            border-color: #bc0ad9;
        }
    }
}
</style>