<template>
  <div class="wrapper-talks">
    <div class="wrapper-talks">
      <div class="talks-header">Chats</div>
      <div class="container-chat">
        <div class="section-chats">
          <div v-for="item in conversations" :key="`it_${item.id}`" class="item__chat">
            <div class="country-session">{{item.user_code_country}}</div>
            <div class="ip-session">{{ item.user_ip }}</div>
            <div class="time-session">{{ formatDate(item.createdAt) }}</div>
            <div class="last-session">{{item.countMessages}}</div>
            <div class="open-session" @click="() => openDialog(item.id)">Open</div>
            <div class="new-session" v-if="!item.isRead">new</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { doRequest } from '@/utils';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

export default {
  name: 'WidgetTalks',
  data() {
    return {
      conversations: [],
    };
  },
  async mounted() {
    const response = await doRequest({
      endpoint: 'all-talk-sessions',
      method: 'POST',
      sentData: { siteSlug: this.$route.params.siteSlug }
    });
    this.conversations = response.conversations;
  },
  methods: {
    formatDate(date) {
      dayjs.extend(utc);
      return dayjs.utc(date).local().format('HH:mm DD.MM.YYYY');
    },
    openDialog(id) {
      this.$router.push({ name: 'Conversation', params: { sessionId: id } });
    },
  },
};
</script>

<style lang="scss" scoped>
.country-session {
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;
}
.item__chat {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0 10px;
  height: 65px;
  min-height: 65px;
  background: #fefdff;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-size: 14px;
}
// .container-chat {
//   width: 100%;
//   height: 500px;
//   padding: 10px 20px;
//   overflow-y: scroll;
//   box-sizing: border-box;
//   &::-webkit-scrollbar {
//     width: 6px;
//     border-radius: 10px;
//   }

//   &::-webkit-scrollbar-track {
//     background: #d3d3d366;
//     border-radius: 10px;
//   }

//   &::-webkit-scrollbar-thumb {
//     background: #d3d3d3;
//     border-radius: 10px;
//     background-clip: content-box;
//   }
// }
.section-chats {
  display: flex;
  height: 100%;
  flex-direction: column;
  gap: 20px;
}
.open-session {
  width: 80px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg, #bc0ad9 0%, #900ad9 70.99%, #7e0ad9 100%);
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.25);
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    transform: scale(1.1);
  }
}
.new-session {
  position: absolute;
  top: 0;
  left: 50px;
  background: #7e0ad9;
  width: 50px;
  color: #fff;
  text-align: center;
  border-radius: 0 0 5px 5px;
}
</style>
