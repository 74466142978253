<template>
  <div class="widget-snippet">

    <h2>Token Usage Info</h2>
    <span v-if="tokenBalance"> Used {{ tokenBalance.token_balance_used }} out of  {{ tokenBalance.token_balance_allocated }} ({{persentage}})</span>
    <div class="chart-container">
      <!-- {{ usageData }} -->
      <canvas style='width: 100%' ref="chartCanvas"></canvas>
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js/auto';
import { useSiteStore } from '@/stores/site.js';
import { mapState, mapActions } from 'pinia';



export default {
  name: 'ChartComponent',
  data() {
    return {
      chart: null,

    };
  },
  async mounted() {
    await this.$router.isReady();
    await this.loadUsageData();
    this.renderChart();
  },
  unmounted(){
    if (this.chart) {
      this.chart.destroy();
    }
  },
  computed: {
    ...mapState(useSiteStore, ['usageData', 'tokenBalance', 'slug']),
    persentage: function(){
      if (this.tokenBalance) {

        if (this.tokenBalance.token_balance_used === 0) {
          return '0%';
        }


        return (100 / this.tokenBalance.token_balance_allocated * this.tokenBalance.token_balance_used).toFixed(2) + '%';
      }
      else {
        return '';
      }
    },
  },
  watch: {
    usageData(new_, old){
      console.log('usageData', new_, old)
      // this.renderChart();
    },
  },
  methods: {
    ...mapActions(useSiteStore, ['loadUsageData']),
    
    renderChart() {
      if (!this.usageData) {
        console.log('no data', this.usageData);
        return;
      }
      const data = this.usageData.reduce((acc,cur) => {
        acc.total.push(cur.total);
        acc.selectedDomain.push(cur[this.slug]);
        acc.labels.push(cur.date.toISOString().slice(0,10) );
        return acc;
      }, {
        'total':[], 
        'selectedDomain': [],
        'labels':[]
      });

      console.log('data', data);
      const ctx = this.$refs.chartCanvas.getContext('2d');
      
      this.chart = new Chart(ctx, {
        type: 'bar',
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        data: {
          labels: data.labels,
          datasets: [
            {
              label: 'Total Token Usage',
              data: data.total,
              fontSize: 16,
              backgroundColor: '#900AD9',
              borderRadius: 13,
              borderWidth: 2,
              barThickness: 40,
              hoverBackgroundColor: 'rgba(255, 99, 132, 0.4)', // Specify hover background color
            hoverBorderColor: 'rgba(255, 99, 132, 1)' //
            },
            {
              label: `${this.slug} Usage`,
              data: data.selectedDomain,
              fontSize: 16,
              backgroundColor: '#be92d6',
              borderRadius: 13,
              borderWidth: 2,
              barThickness: 40,
              hoverBackgroundColor: 'rgba(255, 99, 132, 0.4)', // Specify hover background color
            hoverBorderColor: 'rgba(255, 99, 132, 1)' //
            },
          ],
        },
        options: {
            maintainAspectRatio: false,
            responsive: true,
            plugins: {
                tooltip: {
                    enabled: true, // Enable tooltips
                    mode: 'index', // Set the mode for tooltips
                    intersect: false // Allow tooltips to intersect with bars
                }
            },
            scales: {
                y: {
                    beginAtZero: true
                }
            }
        }
      });
    },
  },
};
</script>

<style scoped>
.chart-container {
  width: 100%;
  height: 300px;
}
canvas {
  width: 100% !important;
  height: auto;
}
</style>
