<template>
  <div>
    <router-view></router-view>
    <div class="alerts">
      <div v-for="alert in siteStore.alerts" :key="alert.id" :class="alert.type" class="alert">
        <span>{{ alert.message }}</span>
        <div class="close" @click="closeAlert(alert.id)">✕</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import '@/assets/base.scss';
import '@/components/signDialog.scss';

import { useSiteStore } from '@/stores/site';
const siteStore = useSiteStore();

const closeAlert = async (id) => {
  await siteStore.closeAlert({ id });
};

</script>

<style lang="scss">
/* #app */
body,
input,
textarea,
button {
  font-family: Roboto; /* Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  background-color: #e5e5e5;
  color: #2c3e50;
}


.alerts {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 1000;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;

  .alert {
    padding: 10px;
    margin: 5px 0;
    border-radius: 5px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
    position: relative;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 5px;

    .close {
      display: flex;
      align-items: flex-start;
      user-select: none;
      cursor: pointer;
    }

    &.error {
      background-color: #ff0000;
      color: #fff;
    }
    &.success {
      background-color: #078207;
      color: #fff;
    }
  }


}


a {
  color: #b200ff;
}

body {
  padding: 0;
  margin: 0;
}

#app {
  max-width: 850px;
  width: 850px;
  margin: 0 auto;
  height: 100vh;
}

.clickable {
  transition: 0.4s;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
    filter: brightness(1.05);
  }

  &.link {
    color: $activeFixed;
  }
}

[center] {
  display: flex;
  align-items: center;
  justify-content: center;
}

[start] {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

[vertical-flex] {
  flex-direction: column;
}
</style>
