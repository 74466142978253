<template>
  <div class="wrapper-full-height fl-col">
    <div class="chat-header">
      <div class="educate-header__item">
        <div class="educate-header__item-image">
          <img
            v-if="domain_bot.bot_icon"
            class="my-bot-logo"
            :src="domain_bot.bot_icon"
          />
          <i v-else class="my-icon my-icon-logo"></i>
        </div>
        <div class="educate-header__item-info">
          <div class="name-bot">{{ domain_bot.bot_name }}</div>
          <div class="text-under-name">Assistant</div>
        </div>
      </div>
      <div class="educate-header__item">
        <div class="educate-header__item-info">
          <div class="name-bot">User</div>
        </div>
        <div class="educate-header__item-image">
          <i class="my-icon my-icon-user"></i>
        </div>
      </div>
    </div>
    <div class="wrapper-conversation fl-col fl-gap-1">
      <div class="content-conversation custom-scroll" v-if="formattedMessages.length">
        <Message
          v-for="item in formattedMessages"
          :key="`msg_${item.id}`"
          :id="item.id"
          :message="item.message"
          :isBot="item.isBot"
          @correction="(e) => correctionMessage(e)"
        />
      </div>
      <div class="content-conversation fl-col fl-a-cent" v-else>
        To educate bot just start talking to him, and edit his answers if they are wrong or you
        don't like them or they are wrong.
      </div>
    </div>
  </div>
</template>

<script setup>
import Message from '@/components/Message';

import { computed, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

import { doRequest } from '@/utils';

const route = useRoute();
const messages = ref([]);
const domain_bot = ref({});

onMounted(async () => {
  const response = await doRequest({
    endpoint: 'session',
    method: 'POST',
    sentData: {
      id: route.params.sessionId,
      slug: route.params.siteSlug
    },
  });

  messages.value = response.messages;
  domain_bot.value = response.domain_bot;
});

const formattedMessages = computed(() => {
  const arr = messages.value.reduce((acc, item) => {
    let newItem = [];
    const f = item.message.split('__NEW__');
    f.forEach((element) => {
      newItem.push({
        ...item,
        isBot: item.owner === 'assistant',
        message: element,
      });
    });
    return [...acc, ...newItem];
  }, []);

  arr.sort((a, b) => +new Date(a.createdAt) - +new Date(b.createdAt));

  return arr;
})

async function correctionMessage(editedMessage) {
  const message = messages.value.find((x) => x.id === editedMessage.id);
  if (!message) {
    console.error('Message not found');
    return;
  }
  message.message = editedMessage.message;
  await doRequest({
    sentData: {
      id: message.id,
      message: editedMessage.message,
      answer_to_id: message.answer_to_id,
      slug: route.params.siteSlug,
    },
    endpoint: 'correct-message',
  });
}
</script>

<style lang="scss" scoped>
.educate-header__item {
  display: flex;
  align-items: center;

  &-image {
    .my-icon {
      font-size: 30px;
    }
    .my-bot-logo{
      width: 35px;
      height: 35px;
      border-radius: 50%;
    }
  }

  &-info {
    margin: 0 10px;
  }

  .name-bot {
    font-size: 14px;
    font-weight: bold;
    color: $text;
  }

  .text-under-name {
    font-size: 14px;
  }
}
</style>
