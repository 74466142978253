<template>
  <div class="Dashboard">Dashboard Hello: {{ email }}#{{ id }}</div>
</template>

<script>
import { doRequest } from '@/utils';

export default {
  name: 'Dashboard',
  data() {
    return {
      email: '',
      id: '',
    };
  },

  async mounted() {
    const info = await doRequest({
      endpoint: 'getUserInfo',
    });
    this.email = info.email;
    this.id = info.id;
  },
};
</script>

<style scoped></style>
