<template>
  <div class="StartPage">
    <div class="in">
      <div id="top">
        <router-link id="logo" to="/">
          <i class="my-icon my-icon-logo"></i>
          Alreadybot
        </router-link>
        <div id="links">
          <div v-if="currentDomain" @click='goToDomain()' class='white-btn clickable'>
            Go to {{ currentDomain }}
          </div>
          <router-link v-if="!currentDomain" class='white-btn clickable' :to="{ name: 'SignUp' }">
            Sign up
          </router-link>
          <router-link v-if="!currentDomain" class='white-btn clickable' :to="{ name: 'SignIn' }">
            Login
          </router-link>
          <div v-if="currentDomain" class='white-btn clickable' @click='logout()'>Logout</div>
        </div>
      </div>
      <div id="medium">
        <div class="left">
          <div id="main-title">
            <h1>
              Already AI bot answers in support chat on your website instead of
              humans
            </h1>
          </div>
          <div id="main-under-title">
            <div class="left">
              <h2>Why AI bot?</h2>
              <ul>
                <li>Available 24/7</li>
                <li>Always polite and in good mood</li>
                <li>Responds quickly</li>
                <li>Bot is “cheaper” then humans</li>
                <li>Based on powerful ChatGPT 3.5 Turbo</li>
                <li>
                  AI bots make mistakes,<br />
                  but already you can educate your own
                </li>
              </ul>
              <router-link :to="{ name: 'SignUp' }" id="trial-btn" class="white-btn clickable">
                Start trial
              </router-link>
            </div>
            <!-- <div class="right">
              <img src="../assets/brain.png" />
            </div> -->
          </div>
        </div>
        <div class="right">
          <video autoplay preload="metadata" loop muted crossorigin playsinline>
            <source src="../assets/widget.webm" type="video/webm">
          </video>
        </div>
      </div>
    </div>
    <div id="pointer">👋 I am your bot, talk to me</div>
  </div>
</template>

<script>
import { useSiteStore } from '@/stores/site.js';
import { doRequest } from '@/utils.js';
import { mapActions, mapState } from 'pinia';
import * as THREE from 'three';


export default {
  name: 'StartPage',
  components: {},
  data() {
    return {

    };
  },
  unmounted() {
    document.querySelector('body').removeAttribute('landing');
    this.animationRunning = false;

    const vm_ = this;
    clearInterval(vm_.cleaner);
    document.body.removeChild(vm_.renderer.domElement);
    document.body.removeEventListener('pointermove', vm_.onPointerMove);
    window.removeEventListener('resize', vm_.onWindowResize);
  },
  computed: {
    ...mapState(useSiteStore, ['slug', 'availableDomains']),
    currentDomain() {
      if (!this.slug) {
        return ''
      }
      return this.availableDomains ? this.availableDomains.find(el => el.slug == this.slug).domain : ''
    }
  },
  methods: {
    ...mapActions(useSiteStore, ['loadAvailableDomains', 'setDomainBySlug']),
    goToDomain() {
      this.setDomainBySlug({ siteSlug: this.slug });
      window.location.href = `widget/${this.slug}/settings`
    },
    async logout() {
      await doRequest({
        sentData: {},
        endpoint: 'logout',
      });
      window.location.reload();
    }
  },
  async mounted() {
    document.querySelector('body').setAttribute('landing', 1);
    this.animationRunning = true;

    const SCREEN_WIDTH = window.innerWidth,
      SCREEN_HEIGHT = window.innerHeight,
      r = 450;

    let mouseY = 0,
      windowHalfY = window.innerHeight / 2,
      camera,
      scene;
    const vm_ = this;

    function init() {
      camera = new THREE.PerspectiveCamera(
        80,
        SCREEN_WIDTH / SCREEN_HEIGHT,
        1,
        3000
      );
      camera.position.z = 1000;
      scene = new THREE.Scene();
      const parameters = [
        [0.25, 0xff7700, 1],
        [0.5, 0xff9900, 1],
        [0.75, 0xffaa00, 0.75],
        [1, 0xffaa00, 0.5],
        [1.25, 0x000833, 0.8],
        [3.0, 0xaaaaaa, 0.75],
        [3.5, 0xffffff, 0.5],
        [4.5, 0xffffff, 0.25],
        [5.5, 0xffffff, 0.125],
      ];
      const geometry = createGeometry();

      for (let i = 0; i < parameters.length; ++i) {
        const p = parameters[i];
        const material = new THREE.LineBasicMaterial({
          color: p[1],
          opacity: p[2],
        });
        const line = new THREE.LineSegments(geometry, material);
        line.scale.x = line.scale.y = line.scale.z = p[0];
        line.userData.originalScale = p[0];
        line.rotation.y = Math.random() * Math.PI;
        line.updateMatrix();
        scene.add(line);
      }

      vm_.renderer = new THREE.WebGLRenderer({ antialias: true });
      vm_.renderer.setPixelRatio(window.devicePixelRatio);
      vm_.renderer.setSize(SCREEN_WIDTH, SCREEN_HEIGHT);

      document.body.style.touchAction = 'none';
      vm_.renderer.domElement.classList.add('home-canvas');

      document.body.appendChild(vm_.renderer.domElement);


      document.body.addEventListener('pointermove', vm_.onPointerMove);
      window.addEventListener('resize', vm_.onWindowResize);

      vm_.cleaner = setInterval(function () {
        const geometry = createGeometry();

        scene.traverse(function (object) {
          if (object.isLine) {
            object.geometry.dispose();
            object.geometry = geometry;
          }
        });
      }, 1000);

    }

    function createGeometry() {
      const geometry = new THREE.BufferGeometry();
      const vertices = [];

      const vertex = new THREE.Vector3();

      for (let i = 0; i < 500; i++) {
        vertex.x = Math.random() * 2 - 1;
        vertex.y = Math.random() * 2 - 1;
        vertex.z = Math.random() * 2 - 1;
        vertex.normalize();
        vertex.multiplyScalar(r);

        vertices.push(vertex.x, vertex.y, vertex.z);

        vertex.multiplyScalar(Math.random() * 0.09 + 1);

        vertices.push(vertex.x, vertex.y, vertex.z);
      }

      geometry.setAttribute(
        'position',
        new THREE.Float32BufferAttribute(vertices, 3)
      );

      return geometry;
    }

    vm_.onWindowResize = function () {
      windowHalfY = window.innerHeight / 2;

      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();

      vm_.renderer.setSize(window.innerWidth, window.innerHeight);
    }

    vm_.onPointerMove = function (event) {
      if (event.isPrimary === false) return;
      mouseY = event.clientY - windowHalfY;
    }

    function animate() {
      if (!vm_.animationRunning) {
        return;
      }
      requestAnimationFrame(animate);
      render();
    }

    function render() {
      camera.position.y += (-mouseY + 200 - camera.position.y) * 0.05;
      camera.lookAt(scene.position);

      vm_.renderer.render(scene, camera);

      const time = Date.now() * 0.0001;

      for (let i = 0; i < scene.children.length; i++) {
        const object = scene.children[i];

        if (object.isLine) {
          object.rotation.y = time * (i < 4 ? i + 1 : -(i + 1));

          if (i < 5) {
            const scale =
              object.userData.originalScale *
              (i / 5 + 1) *
              (1 + 0.5 * Math.sin(7 * time));

            object.scale.x = object.scale.y = object.scale.z = scale;
          }
        }
      }
    }
    init();
    animate();
    await this.$router.isReady();
    // await this.loadAvailableDomains({ siteSlug: localStorage.getItem('currentDomainSlug') });
  },
};
</script>

<style>
.home-canvas {
  position: fixed;
  top: 0;
  z-index: -1;
  opacity: 0.15;
  pointer-events: none;
}

body[landing] {
  background: linear-gradient(90deg, #a24fb5 0%, #337cb7 100%);
}
</style>
<style scoped lang="scss">
.StartPage {

  // background: linear-gradient(90deg, #a24fb5 0%, #337cb7 100%);
  display: flex;
  height: 100vh;
  width: 100vw;
  color: white;
  align-items: center;
  justify-content: center;

  .in {
    display: flex;
    flex-direction: column;
    width: 950px;
  }

  font-size: 16px;

  h1 {
    font-size: 28px;
  }

  h2 {
    font-size: 18px;
  }
}

.white-btn {
  background: linear-gradient(90deg, #ffffff 0%, #eaeaea 100%);
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.25);
  color: $text;
  border-radius: 5px;
  border: 0;
  padding: 10px 25px;
  display: flex;
  min-width: 1px;
  text-decoration: none;
}

#main-title {
  margin-bottom: 30px;
  max-width: 500px;
}

#trial-btn {
  margin-top: 50px;
}

#logo {
  color: white;
  text-decoration: none;
  display: flex;
  font-size: 18px;

  >i {
    margin-right: 5px;
  }
}

#top {
  display: flex;
}

#links {
  margin-left: auto;
  display: flex;
  font-size: 16px;
  gap: 20px;

  a {
    text-decoration: none;
  }
}

#medium {
  margin-top: 50px;
  display: flex;

  >.left {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  3% {
    transform: scale(1.1);
  }

  4% {
    transform: scale(1);
  }

  7% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

#main-under-title {
  display: flex;

  >.left {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
  }

  >.right {
    overflow: visible;
    max-height: 290px;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    display: flex;
    user-select: none;

    img {
      opacity: 0.7;
      width: 290px;
      z-index: -1;
      pointer-events: 0;
      animation: spin 101s linear infinite;
    }

    animation: pulse 2s linear infinite;
  }

  ul li {
    line-height: 26px;
  }
}

#medium {
  >.right {
    margin-left: auto;
    user-select: none;

    video {
      margin-right: -15px;
      width: 350px;
    }
  }
}


@keyframes shifter {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(20px);
  }

  100% {
    transform: translateX(0);
  }
}

#pointer {
  background: #d9e4f0;
  position: fixed;
  right: 138px;
  bottom: 18px;
  color: $text;
  display: flex;
  align-items: center;
  box-shadow: $buttonShadow;
  padding: 0 15px;
  border-radius: 5px 0 0 5px;

  &:after {
    content: "";
    width: 0;
    height: 0;
    border-top: 26px solid transparent;
    border-bottom: 26px solid transparent;
    border-left: 26px solid #d9e4f0;
    margin-right: -41px;
  }

  animation: shifter 2s ease-in-out infinite;
}
</style>

<style>
.wrapper-notification {
  display: none !important;
}
</style>