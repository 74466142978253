export async function doRequest({ sentData, endpoint, method = 'POST' }) {
  const requestOptions = {
    method: method,
    body: sentData && JSON.stringify(sentData),
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    }
  };
  console.log(`HTTP request to %c ${endpoint}`, 'color: blue', `: ${requestOptions}`);

  const host = window.location.host.includes('alreadybot') ? '' : 'http://localhost:8000';
  const response = await fetch(`${host}/api/${endpoint}`, requestOptions);
  const responseBody = await response.json();
  if (responseBody && responseBody.detail === 'Auth expired') {
    document.location.href = '/';
  }
  // console.log(`HTTP response to %c ${endpoint}`, 'color: green', `: ${JSON.stringify((responseBody))}`);
  return responseBody;
}
