<template>
  <div class="item-message" :class="{ request: !isBot }">
    <div class="item-message__text">{{ message }}</div>
    <div v-if="isBot && !enableEditor" @click="activateEditor" class="edit__text">
      <i class="my-icon my-icon-pencil"></i>
    </div>
    <div v-if="isBot && enableEditor" class="item-message__editor">
      <div class="editor-label">Correct answer</div>
      <input
        ref="input"
        class="editor-input"
        type="text"
        @keydown.enter="saveEditedMessage"
        :value="message"
      />
      <div class="button-editor">
        <div class="item__button" @click="deactivateEditor">
          <i class="my-icon my-icon-close"></i>
        </div>
        <div class="item__button" @click="saveEditedMessage">
          <i class="my-icon my-icon-save"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { doRequest } from '@/utils';

export default {
  name: 'WidgetEducate',
  props: {
    id: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    isBot: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  data() {
    return {
      enableEditor: false,
    };
  },
  methods: {
    activateEditor() {
      this.enableEditor = true;
    },
    deactivateEditor() {
      this.enableEditor = false;
    },
    saveEditedMessage() {
      const editedMessage = this.$refs.input.value.trim();
      const currentMessage = this.message.toString().trim();

      if (editedMessage !== currentMessage) {
        this.$emit('correction', { message: editedMessage, id: this.id });
      }
      this.deactivateEditor();
    },
  },
};
</script>

<style lang="scss" scoped>
.item-message {
  color: black;
  background: linear-gradient(90deg, #dadada 0%, #e8e8e8 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  max-width: 250px;
  padding: 10px 45px 10px 20px;
  border-radius: 5px;
  position: relative;
  .edit__text {
    position: absolute;
    right: 10px;
    cursor: pointer;
    i {
      font-size: 16px;
      max-height: 16px;
      color: black;
    }
  }
  &__editor {
    position: absolute;
    top: 100%;
    width: calc(100% + 80px);
    height: 100%;
    left: 0;
    top: 100%;
    margin-top: 15px;
    z-index: 99;
    background: #fff;
    padding: 5px;
    box-shadow: 0px 0px 2px #000;
    border-radius: 10px;
  }
}
.request {
  margin-left: auto;
  padding: 10px 20px 10px 20px;
  background: linear-gradient(90deg, #d75eea 0%, #9b48d6 100%);
  color: white;

}
.editor-input {
  width: 100%;
  height: 100%;
  max-height: 40px;
  min-width: 100%;
  box-sizing: border-box;
  border: 1px solid #bc0ad9;
  outline: none;
  background: none;
  padding: 5px 55px 3px 8px;
  border-radius: 5px;
}
.editor-label {
  position: absolute;
  width: 70px;
  padding: 0px 2px;
  margin-left: 10px;
  background: #fff;
  font-size: 10px;
  top: 0;
  left: 0;
  text-align: center;
}
.button-editor {
  display: flex;
  gap: 10px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  .item__button {
    cursor: pointer;
  }
  i {
    font-size: 16px;
    max-height: 16px;
    max-width: 16px;
    display: block;
    color: #bc0ad9;
  }
}
</style>
