<template>
  <div class="Widget">
    <div class="widget-nav-container">
      <div class="widget-nav-top-section">
        <router-link :to="{ name: 'StartPage' }" style="text-decoration: none; color: inherit">
          <Logo start>alreadybot</Logo>
        </router-link>
        <div style="display: flex; flex-direction: column;">
          on domain:
          <BaseSelect
            v-model="currentDomainInfo"
            :options="availableDomains"
            :label="'domain'"
            :placeholder="'Select domain'"
            :hide-clear-action="true"
            @input="changeDomain"
          >
            <template #list-footer>
              <div class="link select-footer" @click="onAddDomain">
                <span>Add domain</span>
                <i class="my-icon my-icon-add"></i>
              </div>
            </template>
          </BaseSelect>
        </div>
      </div>
      <div class="widget-nav">
        <router-link class="clickable link" :to="{ name: 'WidgetOverview' }">
          <span>Overview</span>
        </router-link>
        <router-link class="clickable link" :to="{ name: 'WidgetTalks' }">
          <span>Talk sessions</span>
        </router-link>
        <router-link class="clickable link" :to="{ name: 'WidgetKnowledge' }">
          <span>Knowledge base</span>
        </router-link>
        <router-link class="clickable link" :to="{ name: 'WidgetEducate' }">
          <span>Test bot</span>
        </router-link>
        <router-link class="clickable link" :to="{ name: 'WidgetSettings' }">
          <span>Settings</span>
        </router-link>
        <router-link class="clickable link" :to="{ name: 'WidgetSnippet' }">
          <span>Widget Snippet</span>
        </router-link>
        <router-link class="clickable link" :to="{ name: 'Pricing' }">
          <span>Pricing</span>
        </router-link>
      </div>
      <!-- <div class="CorrectionBlock" v-if="isWidgetTalks">
        {{ widgetTalksCountEducate }} corrections
        <MyButton @click="onBtnEducateTalks()" :width="'fit-content'">Confirm corrections</MyButton>
      </div>
      <div class="CorrectionBlock" v-if="isWidgetEducate">
        {{ widgetEducateCountEducate }} corrections
        <MyButton @click="onBtnEducateEducate()" :width="'fit-content'"
          >Confirm corrections</MyButton
        >
      </div> -->
      <div class="widget-nav-bottom-section">
        <span class="clickable link" @click="logout()">Logout</span>
      </div>
      
    </div>
    <div class="widget-body">
      <!-- vf=domain postpose loading of sub-pages which relay on domain -->
      <router-view v-if="domain"></router-view>
    </div>
  </div>
</template>

<script>
import BaseSelect from '@/components/BaseSelect.vue';
import Logo from '@/components/Logo.vue';
import eventBus from '@/router/eventBus';
import { useSiteStore } from '@/stores/site.js';
import { doRequest } from '@/utils';

import { mapActions, mapState } from 'pinia';

export default {
  name: 'Widget',
  components: { Logo, BaseSelect },
  data() {
    return {
      isWidgetTalks: false,
      isWidgetEducate: false,
      widgetTalksCountEducate: 0,
      widgetEducateCountEducate: 0,
      currentDomainInfo: null,
    };
  },
  computed: {
    ...mapState(useSiteStore, ['domain', 'availableDomains', 'currentDomain']),
  },
  async mounted() {
    console.log('MOUNTED')
    await this.$router.isReady();

    await this.loadAvailableDomains({ siteSlug: this.$route.params.siteSlug });
    this.currentDomainInfo = this.currentDomain;

    eventBus.on('widget-talks spawned', () => {
      this.isWidgetTalks = true;
    });
    eventBus.on('widget-talks destroyed', () => {
      this.isWidgetTalks = false;
    });
    eventBus.on('widget-educate spawned', () => {
      this.isWidgetEducate = true;
    });
    eventBus.on('widget-educate destroyed', () => {
      this.isWidgetEducate = false;
    });

    eventBus.on('widget-talks correction count changed', (data) => {
      if (data) {
        this.widgetTalksCountEducate = data.count;
      }
    });
    eventBus.on('widget-educate correction count changed', (data) => {
      if (data) {
        this.widgetEducateCountEducate = data.count;
      }
    });
  },
  unmounted() {
    eventBus.off('widget-talks correction count changed');
    eventBus.off('widget-talks destroyed');
    eventBus.off('widget-talks spawned');
    eventBus.off('widget-educate correction count changed');
    eventBus.off('widget-educate destroyed');
    eventBus.off('widget-educate spawned');
  },
  methods: {
    ...mapActions(
      useSiteStore,
      ['loadAvailableDomains', 'setDomainBySlug']
    ),
    async onBtnEducateTalks() {
      eventBus.emit('widget-talks educate bot');
    },
    async onBtnEducateEducate() {
      eventBus.emit('widget-educate educate bot');
    },
    async onLogout() {
      this.$router.push('/');
      document.cookie = 'jwtToken' + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    },
    async onAddDomain() {
      this.$router.push({ name: 'AddWebsite' });
    },
    changeDomain(value) {
      if (value.slug === this.$route.params.siteSlug) return;
      
      this.setDomainBySlug({ siteSlug: value.slug });

      window.location.href = this.$router.resolve({
        name: this.$route.name,
        params: { ...this.$route.params, siteSlug: value.slug }
      }).href;

      // this.$router.push({
      //   name: this.$route.name,
      //   params: {
      //     ...this.$route.params,
      //     siteSlug: value.slug,
      //   },
      // });
    },
    async logout() {
      await doRequest({
        sentData: {},
        endpoint: 'logout',
      });
      window.location.href = '/';
    }
  },
};
</script>

<style scoped lang="scss">
.bold {
  display: inline-block;
  font-weight: bold;
}

.Widget {
  width: 100dvw;
  height: 100dvh;
  display: flex;
  flex-direction: row;
}

.widget-nav-container {
  width: 200px;
  max-width: 225px;

  line-height: 32px;

  display: flex;
  flex-direction: column;
  padding-top: 0;
  flex-shrink: 0;
}

.widget-nav {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.widget-nav a {
  text-decoration: none;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  height: 100%;

  padding-left: 24px;

  box-sizing: border-box;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.widget-nav li:active {
  background-color: #918d8de8;
}

.widget-nav li:hover {
  cursor: pointer;
}

.widget-nav a.router-link-exact-active {
  background: $active;

}

.widget-nav a.router-link-exact-active span {
  color: white;
}

.widget-nav-top-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;

  margin-bottom: 24px;

  > * {
    padding-left: 24px;
  }
}

.widget-nav-bottom-section {
  margin-top: auto;
  margin-bottom: 5px;
}

.widget-nav-bottom-section span {
  padding-left: 24px;
  
  display: inline-flex;
  width: 100%;
}

.widget-body {
  padding: 30px;
  flex-grow: 1;
}

.select-footer {
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  > * {
    color: $activeFixed;
    cursor: pointer;
  }
}

// .CorrectionBlock {
//   margin-top: 40px;
//   padding: 20px;
//   display: flex;
//   width: fit-content;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.25);
//   border-radius: 5px;
// }
</style>
