<template>
  <div class="logoWrap">
      <div class="logoImg">
        <i class="my-icon my-icon-logo"></i>
        <!-- <img src="@/assets/images/ab_logo.svg"> -->
      </div>
      <div class="logoLbl">
        &nbsp;<slot></slot>
      </div>
  </div>
</template>

<script>
export default {
  name: 'Logo',

};
</script>

<style scoped>

.logoImg{
  font-size: 22px;

}
    .logoLbl{
      font-size: 18px;
      font-weight: bolder;
      color: #0B315E;
    }
</style>
